import { HEATMAP_BY_TYPES } from '@/plan/constants';
export function serializeLegend(legend) {
    return {
        poisVisibility: legend.poisVisibility ?? true,
        poisBufferVisibility: legend.poisBufferVisibility ?? true,
        boundariesVisibility: legend.boundariesVisibility ?? true,
        assetsIdsLayerVisibility: legend.assetsIdsLayerVisibility ?? false,
        colorizeAssets: legend.colorizeAssets ?? true,
        productClass: legend.productClass ?? 'l2product_id',
        canAssetsExcludedLayerBeShown: legend.canAssetsExcludedLayerBeShown ?? true,
        excludeAssets: legend.excludeAssets ?? false,
        facingOrientationsIcons: legend.facingOrientationsIcons ?? false,
        angleNorthMap: legend.angleNorthMap ?? false,
        hiddenProductsId: legend.hiddenProductsId ?? [],
        heatmapAssetsVisibility: legend.heatmapAssetsVisibility ?? false,
        heatmapAssetsType: legend.heatmapAssetsType ?? HEATMAP_BY_TYPES.IMPRESSIONS,
    };
}
