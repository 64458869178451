/* eslint-disable @typescript-eslint/no-explicit-any */
import { CIRCLE_STEPS, HEATMAP_BY_TYPES } from './constants';
import { circle } from '@turf/turf';
import { getAppMetadata } from './metadata/metadata';
import { getAudienceFeatureVersion } from './metadata/metadata.helper';
import { Audience, Package } from '@workspaces/types';
import { isPlanPackageExpiredForNoAgencyConfirmation } from './filter/packages.helper';
function deserializeCountries(countries) {
    // validate no null values in the country array
    const checkedCountries = countries.filter((c) => c);
    if (!checkedCountries.length) {
        return getAppMetadata().countries.map((c) => c.id);
    }
    return checkedCountries;
}
function deserializeGeoboundaries(geoboundaries) {
    return geoboundaries;
}
function deserializeUserAudiencesGroup(userAudiencesGroup) {
    // Legacy purposes, id needs to be set if legacy plan have it unset
    const userAudiencesGroupWithId = userAudiencesGroup.map((audience) => {
        if (!audience.id) {
            const time = new Date().getTime();
            audience.id = `audience-${time}`;
        }
        return audience;
    });
    return userAudiencesGroupWithId;
}
function deserializeAudiences(audiences) {
    const audiencesDeserialized = {
        audiences: audiences.audiences || [],
        audiencesGroups: audiences.audiencesGroups || [],
        audiencesIndex: audiences.audiencesIndex,
        audiencesPercent: audiences.audiencesPercent,
        audiencesType: audiences.audiencesType,
        delivery: audiences.delivery || null,
        userAudiencesGroup: audiences.userAudiencesGroup
            ? deserializeUserAudiencesGroup(audiences.userAudiencesGroup)
            : [],
    };
    // We may have old plans saving delivery for V1, so this is a hack to always use Latest Delivery for V1
    if (getAudienceFeatureVersion() === Audience.AudienceFeatureVersion.V1) {
        audiencesDeserialized.delivery = null;
    }
    return audiencesDeserialized;
}
function deserializePolygons(features) {
    const parsedFeatures = typeof features === 'string' ? JSON.parse(features) : features;
    if (features.type === 'FeatureCollection' &&
        Array.isArray(features.features)) {
        return features;
    }
    return {
        type: 'FeatureCollection',
        features: parsedFeatures.map((f) => {
            if (f.geometry.type === 'Point') {
                if (f.properties.units === 'meters') {
                    // TODO: convert to kilometers
                }
                const center = [f.properties.center.lng, f.properties.center.lat];
                const circleFeature = circle(center, f.properties.radius, {
                    ...f.properties,
                    steps: CIRCLE_STEPS,
                });
                return { ...circleFeature, properties: { ...f.properties }, id: f.id };
            }
            return f;
        }),
    };
}
function deserializeGeographicalDistribution(geographicalDistribution) {
    return geographicalDistribution;
}
function deserializeDigitalPanel(isDigital) {
    return isDigital === '' ? undefined : isDigital;
}
function deserializeSubfilterPackage(thePackage) {
    return thePackage === '' ? null : thePackage;
}
function removeUndefinedProperty(key, obj) {
    const keyTyped = key;
    if (obj[keyTyped] === undefined) {
        delete obj[keyTyped];
    }
    return obj;
}
export function deserializeFilters(filters) {
    const objFilters = JSON.parse(filters);
    return objFilters.map((subfilter) => {
        const deserialized = {
            tabname: subfilter.tabname || '',
            countries: deserializeCountries(subfilter.countries),
            geoboundaries: deserializeGeoboundaries(subfilter.geoboundaries),
            types: subfilter.types,
            assets_excluded: subfilter.excluded || [],
            assets_included: subfilter.included || [],
            panels: subfilter.panels,
            panelsExcluded: subfilter.panelsExcluded,
            polygon_geom: deserializePolygons(subfilter.polygon_geom || []),
            geographical_distribution: deserializeGeographicalDistribution(subfilter.geographical_distribution),
            package: deserializeSubfilterPackage(subfilter.package || ''),
            // FIXME:
            ...deserializeAudiences(subfilter.audiences),
            isDigital: deserializeDigitalPanel(subfilter.is_digital),
            proximity: subfilter.meta_proximity,
        };
        for (const key in deserialized) {
            removeUndefinedProperty(key, deserialized);
        }
        return deserialized;
    });
}
export function deserializeLegend(legend) {
    if (legend) {
        return {
            ...legend,
            poisVisibility: legend.poisVisibility !== false,
            poisBufferVisibility: legend.poisBufferVisibility !== false,
            boundariesVisibility: legend.boundariesVisibility !== false,
            assetsIdsLayerVisibility: legend.assetsIdsLayerVisibility === true,
            colorizeAssets: legend.colorizeAssets === true,
            angleNorthMap: legend.angleNorthMap === true,
            heatmapAssetsVisibility: legend.heatmapAssetsVisibility === true,
            heatmapAssetsType: legend.heatmapAssetsType ?? HEATMAP_BY_TYPES.IMPRESSIONS,
        };
    }
    else {
        return {
            poisVisibility: true,
            poisBufferVisibility: true,
            boundariesVisibility: true,
            assetsIdsLayerVisibility: false,
            colorizeAssets: false,
            productClass: 'l2product_id',
            canAssetsExcludedLayerBeShown: true,
            excludeAssets: false,
            facingOrientationsIcons: false,
            angleNorthMap: false,
            hiddenProductsId: [],
            heatmapAssetsVisibility: false,
            heatmapAssetsType: HEATMAP_BY_TYPES.IMPRESSIONS,
        };
    }
}
export function deserializePackage(packageFilter) {
    if (!packageFilter || packageFilter === 'null') {
        return null;
    }
    const jsonPackageFilter = JSON.parse(packageFilter);
    const deserialized = {
        state: jsonPackageFilter.state,
        id: jsonPackageFilter.id,
        name: jsonPackageFilter.name,
        price: jsonPackageFilter.price,
        assets: jsonPackageFilter.assets,
        impressions: jsonPackageFilter.impressions,
        availability: jsonPackageFilter.availability,
        filter: jsonPackageFilter.filter,
        bookedConfirmByAdminAt: jsonPackageFilter.bookedConfirmByAdminAt
            ? new Date(jsonPackageFilter.bookedConfirmByAdminAt)
            : undefined,
        expirationForAgencyBookingConfirmation: jsonPackageFilter.expirationForAgencyBookingConfirmation
            ? new Date(jsonPackageFilter.expirationForAgencyBookingConfirmation)
            : undefined,
        bookedFinalizedAt: jsonPackageFilter.bookedFinalizedAt
            ? new Date(jsonPackageFilter.bookedFinalizedAt)
            : undefined,
        expirationForAgencyCancellation: jsonPackageFilter.expirationForAgencyCancellation
            ? new Date(jsonPackageFilter.expirationForAgencyCancellation)
            : undefined,
        actions: [],
    };
    if (jsonPackageFilter.actions) {
        deserialized.actions = jsonPackageFilter.actions.map((action) => {
            return {
                state: action.state,
                timestamp: new Date(action.timestamp),
                user: action.user,
            };
        });
    }
    const dateRange = jsonPackageFilter.filter.dateRange;
    if (Array.isArray(dateRange) && dateRange.length === 2) {
        deserialized.filter.dateRange = [
            new Date(dateRange[0]),
            new Date(dateRange[1]),
        ];
    }
    else {
        deserialized.filter.dateRange = null;
    }
    deserialized.filter.panelType = jsonPackageFilter.filter.panelType;
    deserialized.filter.priceRange = jsonPackageFilter.filter.priceRange;
    if (isPlanPackageExpiredForNoAgencyConfirmation(deserialized)) {
        deserialized.state = Package.State.Expired;
    }
    return deserialized;
}
